import React, { useState, useEffect } from 'react';

interface CreateJoinProps {
    createRoom: (playerName: string, isPublic: boolean, language: string) => void;
    joinRoom: (roomId: string, playerName: string) => void;
}

interface PublicRoom {
    id: string;
    players: number;
    language: string;
}

export const CreateJoin: React.FC<CreateJoinProps> = ({ createRoom, joinRoom }) => {
    const [roomId, setRoomId] = useState('');
    const [playerName, setPlayerName] = useState('');
    const [isPublic, setIsPublic] = useState(true);
    const [language, setLanguage] = useState('en');
    const [publicRooms, setPublicRooms] = useState<PublicRoom[]>([]);

    useEffect(() => {
        fetchPublicRooms();
    }, []);

    const fetchPublicRooms = async () => {
        try {
            const response = await fetch('/api/public-rooms');
            const data = await response.json();
            setPublicRooms(data);
        } catch (error) {
            console.error('Error fetching public rooms:', error);
        }
    };

    return (
        <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white/10 p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4">Create a Room</h2>
                <input
                    type="text"
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                    placeholder="Your Name"
                    className="w-full p-2 mb-4 bg-white/20 rounded"
                />
                <select
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    className="w-full p-2 mb-4 bg-white/20 rounded"
                >
                    <option value="al">Albanian</option>
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                    <option value="fr">French</option>
                </select>
                <label className="flex items-center mb-4">
                    <input
                        type="checkbox"
                        checked={isPublic}
                        onChange={(e) => setIsPublic(e.target.checked)}
                        className="mr-2"
                    />
                    Public Room
                </label>
                <button
                    onClick={() => createRoom(playerName, isPublic, language)}
                    className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                >
                    Create Room
                </button>
            </div>
            <div className="bg-white/10 p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4">Join a Room</h2>
                <input
                    type="text"
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                    placeholder="Your Name"
                    className="w-full p-2 mb-4 bg-white/20 rounded"
                />
                <input
                    type="text"
                    value={roomId}
                    onChange={(e) => setRoomId(e.target.value)}
                    placeholder="Room ID"
                    className="w-full p-2 mb-4 bg-white/20 rounded"
                />
                <button
                    onClick={() => joinRoom(roomId, playerName)}
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                    Join Room
                </button>
            </div>
            <div className="md:col-span-2 bg-white/10 p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4">Public Rooms</h2>
                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead>
                        <tr className="bg-white/20">
                            <th className="p-2">Room ID</th>
                            <th className="p-2">Players</th>
                            <th className="p-2">Language</th>
                            <th className="p-2">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {publicRooms.map((room) => (
                            <tr key={room.id} className="border-b border-white/10">
                                <td className="p-2">{room.id}</td>
                                <td className="p-2">{room.players}/10</td>
                                <td className="p-2">{room.language}</td>
                                <td className="p-2">
                                    <button
                                        onClick={() => joinRoom(room.id, playerName)}
                                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded"
                                    >
                                        Join
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};