import React, { useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import SimplePeer from 'simple-peer';
import '../styles/VoiceChat.css';

interface VoiceChatProps {
    socket: Socket;
    roomId: string;
}

interface PeerConnection {
    peerId: string;
    peer: SimplePeer.Instance;
}

export const VoiceChat: React.FC<VoiceChatProps> = ({ socket, roomId }) => {
    const [isMuted, setIsMuted] = useState(false);
    const [audioLevel, setAudioLevel] = useState(0);
    const [peers, setPeers] = useState<PeerConnection[]>([]);
    const [error, setError] = useState<string | null>(null);

    const localStreamRef = useRef<MediaStream | null>(null);
    const audioContextRef = useRef<AudioContext | null>(null);
    const analyserRef = useRef<AnalyserNode | null>(null);

    useEffect(() => {
        startVoiceChat();

        return () => {
            endVoiceChat();
        };
    }, []);

    useEffect(() => {
        const handleUserJoined = (userId: string) => {
            console.log(`New user joined: ${userId}`);
            if (localStreamRef.current) {
                callPeer(userId, true);
            }
        };

        const handleUserLeft = (userId: string) => {
            console.log(`User left: ${userId}`);
            removePeer(userId);
        };

        const handleReceiveCall = ({ signal, from }: { signal: SimplePeer.SignalData, from: string }) => {
            console.log(`Receiving call from: ${from}`);
            if (localStreamRef.current) {
                answerCall(signal, from);
            }
        };

        socket.on('userJoined', handleUserJoined);
        socket.on('userLeft', handleUserLeft);
        socket.on('receiveCall', handleReceiveCall);

        return () => {
            socket.off('userJoined', handleUserJoined);
            socket.off('userLeft', handleUserLeft);
            socket.off('receiveCall', handleReceiveCall);
        };
    }, [socket]);

    const startVoiceChat = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            localStreamRef.current = stream;

            // Set up audio analysis
            audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
            analyserRef.current = audioContextRef.current.createAnalyser();
            const source = audioContextRef.current.createMediaStreamSource(stream);
            source.connect(analyserRef.current);
            analyserRef.current.fftSize = 256;

            // Start audio level monitoring
            monitorAudioLevel();

            socket.emit('joinVoiceChat', roomId);
        } catch (err) {
            console.error('Error starting voice chat:', err);
            setError('Failed to access microphone. Please check your audio permissions.');
        }
    };

    const endVoiceChat = () => {
        if (localStreamRef.current) {
            localStreamRef.current.getTracks().forEach(track => track.stop());
            localStreamRef.current = null;
        }
        peers.forEach(({ peer }) => peer.destroy());
        setPeers([]);
        socket.emit('leaveVoiceChat', roomId);
    };

    const toggleMute = () => {
        if (localStreamRef.current) {
            localStreamRef.current.getAudioTracks().forEach(track => {
                track.enabled = isMuted;
            });
            setIsMuted(!isMuted);
        }
    };

    const callPeer = (peerId: string, initiator: boolean) => {
        if (!localStreamRef.current) return;

        const peer = new SimplePeer({
            initiator,
            stream: localStreamRef.current,
            trickle: false
        });

        peer.on('signal', (data) => {
            socket.emit('callUser', { userToCall: peerId, signalData: data, from: socket.id });
        });

        peer.on('stream', (stream) => {
            // Create and play audio element for the peer's stream
            const audio = new Audio();
            audio.srcObject = stream;
            audio.play();
        });

        socket.on('callAccepted', ({ signal, from }: { signal: SimplePeer.SignalData, from: string }) => {
            peer.signal(signal);
        });

        setPeers(prevPeers => [...prevPeers, { peerId, peer }]);
    };

    const answerCall = (incomingSignal: SimplePeer.SignalData, callerId: string) => {
        if (!localStreamRef.current) return;

        const peer = new SimplePeer({
            initiator: false,
            stream: localStreamRef.current,
            trickle: false
        });

        peer.on('signal', (data) => {
            socket.emit('answerCall', { signal: data, to: callerId });
        });

        peer.on('stream', (stream) => {
            // Create and play audio element for the peer's stream
            const audio = new Audio();
            audio.srcObject = stream;
            audio.play();
        });

        peer.signal(incomingSignal);

        setPeers(prevPeers => [...prevPeers, { peerId: callerId, peer }]);
    };

    const removePeer = (peerId: string) => {
        setPeers(prevPeers => {
            const peerToRemove = prevPeers.find(p => p.peerId === peerId);
            if (peerToRemove) {
                peerToRemove.peer.destroy();
            }
            return prevPeers.filter(p => p.peerId !== peerId);
        });
    };

    const monitorAudioLevel = () => {
        if (!analyserRef.current) return;

        const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
        const updateAudioLevel = () => {
            analyserRef.current!.getByteFrequencyData(dataArray);
            const average = dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
            setAudioLevel(average);
            requestAnimationFrame(updateAudioLevel);
        };
        updateAudioLevel();
    };

    return (
        <div className="voice-chat">
            <h3>Voice Chat</h3>
            {error && <p className="error">{error}</p>}
            <button onClick={toggleMute}>
                {isMuted ? 'Unmute' : 'Mute'}
            </button>
            <div className="audio-level-indicator" style={{
                width: '100px',
                height: '20px',
                backgroundColor: '#ddd',
                position: 'relative'
            }}>
                <div style={{
                    width: `${audioLevel / 2.55}%`,
                    height: '100%',
                    backgroundColor: isMuted ? 'red' : 'green',
                    transition: 'width 0.1s'
                }}></div>
            </div>
            <p>Connected peers: {peers.length}</p>
        </div>
    );
};