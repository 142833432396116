import React, { useState } from 'react';
import { Socket } from 'socket.io-client';
import { VoiceChat } from './VoiceChat';

interface Player {
    id: string;
    name: string;
    isCreator: boolean;
    isEliminated?: boolean;
    score: number;
}

interface SubmittedWord {
    word: string;
    name: string;
}

interface GameRoomProps {
    roomId: string;
    players: Player[];
    waitingPlayers: Player[];
    word: string | null;
    isImposter: boolean;
    startGame: () => void;
    restartGame: () => void;
    isCreator: boolean;
    round: number;
    submitWord: (word: string) => void;
    roundWords: {[key: string]: string};
    isVoting: boolean;
    submitVote: (votedPlayerId: string) => void;
    language: string;
    gameOver: boolean;
    socket: Socket;
    isWaiting: boolean;
    submittedWords: {[key: string]: SubmittedWord};
    timeLeft: number;
}

export const GameRoom: React.FC<GameRoomProps> = ({
                                                      roomId,
                                                      players,
                                                      waitingPlayers,
                                                      word,
                                                      isImposter,
                                                      startGame,
                                                      restartGame,
                                                      isCreator,
                                                      round,
                                                      submitWord,
                                                      roundWords,
                                                      isVoting,
                                                      submitVote,
                                                      language,
                                                      gameOver,
                                                      socket,
                                                      isWaiting,
                                                      submittedWords,
                                                      timeLeft,
                                                  }) => {
    const [currentWord, setCurrentWord] = useState('');
    const [selectedPlayer, setSelectedPlayer] = useState<string | null>(null);

    const handleWordSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (currentWord.trim()) {
            submitWord(currentWord.trim());
            setCurrentWord('');
        }
    };

    const handleVoteSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (selectedPlayer) {
            submitVote(selectedPlayer);
            setSelectedPlayer(null);
        }
    };

    const currentPlayer = players.find(p => p.id === socket.id);
    const isEliminated = currentPlayer?.isEliminated;

    return (
        <div className="game-room">
            <h2>Game Room</h2>
            <p>Room ID: {roomId}</p>
            <p>Language: {language}</p>
            <VoiceChat socket={socket} roomId={roomId}/>
            <div className="players-container">
                <div className="active-players">
                    <h3>Active Players:</h3>
                    <ul>
                        {players.map((player) => (
                            <li key={player.id}>
                                {player.name} {player.isCreator ? '(Creator)' : ''} {player.isEliminated ? '(Eliminated)' : ''}
                                - Score: {player.score}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="waiting-players">
                    <h3>Waiting Players:</h3>
                    <ul>
                        {waitingPlayers.map((player) => (
                            <li key={player.id}>{player.name}</li>
                        ))}
                    </ul>
                </div>
            </div>
            {isCreator && (round === 0 || gameOver) && (
                <button onClick={gameOver ? restartGame : startGame}>
                    {gameOver ? 'Restart Game' : 'Start Game'}
                </button>
            )}
            {round > 0 && !gameOver && !isWaiting && (
                <div>
                    <h3>Round {round}</h3>
                    <p>Time left: {timeLeft} seconds</p>
                    {isImposter ? (
                        <div>
                            <p>You are the imposter! Try to guess the word.</p>
                            <h4>Submitted words:</h4>
                            <ul>
                                {Object.entries(submittedWords).map(([playerId, { word, name }]) => (
                                    <li key={playerId}>
                                        {name}: {word}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <p>The word is: <strong>{word}</strong></p>
                    )}
                    {!isVoting && !isEliminated && (
                        <form onSubmit={handleWordSubmit}>
                            <input
                                type="text"
                                value={currentWord}
                                onChange={(e) => setCurrentWord(e.target.value)}
                                placeholder="Enter a related word"
                            />
                            <button type="submit">Submit Word</button>
                        </form>
                    )}
                    {Object.keys(roundWords).length > 0 && (
                        <div>
                            <h4>Words submitted this round:</h4>
                            <ul>
                                {Object.entries(roundWords).map(([playerId, word]) => (
                                    <li key={playerId}>
                                        {players.find(p => p.id === playerId)?.name}: {word}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {isVoting && !isEliminated && (
                        <div>
                            <h4>Vote for the Imposter</h4>
                            <form onSubmit={handleVoteSubmit}>
                                <select
                                    value={selectedPlayer || ''}
                                    onChange={(e) => setSelectedPlayer(e.target.value)}
                                >
                                    <option value="">Select a player</option>
                                    {players.filter(p => !p.isEliminated).map((player) => (
                                        <option key={player.id} value={player.id}>
                                            {player.name}
                                        </option>
                                    ))}
                                </select>
                                <button type="submit">Submit Vote</button>
                            </form>
                        </div>
                    )}
                    {isEliminated && (
                        <p>You have been eliminated. You can still watch the game, but you cannot submit words or vote.</p>
                    )}
                </div>
            )}
            {isWaiting && (
                <div className="waiting-message">
                    <p>You are in the waiting list. The game will start for you in the next round.</p>
                    <p>Please wait for the current game to finish.</p>
                </div>
            )}
        </div>
    );
};